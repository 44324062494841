





















































import { FormHelper, Toast } from '@/mixins'
import { Component, Mixins } from 'vue-property-decorator'
import { Admin } from '@/api'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

@Component({
  components: {
    Swiper,
    SwiperSlide,
  }
})
export default class AdminAccountNoteModal extends Mixins(Toast) {
  isOpen = false
  userInfos: UserBanInfo = {} as UserBanInfo
  notes: any[] = []
  newNote = ''
  activeIndex = 1
  swiperOptions = {
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 30,
  }

  $refs!: {
    addNoteForm: HTMLFormElement
    mySwiper: any
  }

  get swiperActiveIndex() {
    return this.$refs.mySwiper.swiperInstance.activeIndex + 1
  }

  onSwipe() {
    this.activeIndex = this.$refs.mySwiper.swiperInstance.activeIndex + 1
  }

  async mounted() {
    this.$root.$on('openAdminAccountNoteModal', async (userInfos) => this.getNotes(userInfos))
  }

  async unmounted() {
    this.$root.$off('openAdminAccountNoteModal', async (userInfos) => this.getNotes(userInfos))
  }

  async getNotes(userInfos) {
    this.isOpen = true
    this.userInfos = userInfos

    const { result } = await Admin.getAccountNoteByAccountId(this.userInfos.id)

    this.notes = result.reverse()
  }

  async addNote(e) {
    e.preventDefault()

    try {
      await Admin.addAccountNote(this.userInfos.id, {
        content: this.newNote
      })

      this.showSuccessToast({
        title: 'Successfully added note!',
        message: `A note has been added for ${this.userInfos.username}.`
      })
      this.isOpen = false
      this.newNote = ''
    } catch(e) {
      console.error(`Error when trying to add note for ${this.userInfos.username}`, e)
    }
  }
}
